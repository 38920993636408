import * as React from "react";
import Layout from "../components/layout";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RPG commission details</title>
        <meta
          name="description"
          content="The secret page you never knew existed."
        />
        <meta name="image" content="https://awmcphee.ca/halftone.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/halftone.jpg" />
        <meta name="twitter:card" content="https://awmcphee.ca/halftone.jpg" />
      </Helmet>
      <h2>RPG map commissions</h2>
      <h3>Prices</h3>
      <p>They're $200.</p>
      <h3>Commission Process</h3>
      <ol><li>Provide me with two ref sheets as stipulated below.</li>
      <li>I'll invoice you for a $100 deposit and start when I'm paid.</li>
      <li>I'll provide you with a first draft.
      <ul><li>Containing everything on sheet 1, and</li>
      <li>a plausible geographic setting for the region, using my geology degree that I actually have.</li></ul></li>
      <li>You provide me with feedback. You get the revised first draft once we've discussed and agreed on the changes.</li>
      <li>I'll provide you with a second draft.<ul><li>Containing everything on sheet 2,</li>
      <li>up to a certain cutoff point, which I will choose based on space, layout, and time constraints.</li>
      </ul></li>
      <li>You provide me with feedback. You get the revised first draft once we've discussed and agreed on the changes.</li>
      <li>As soon as I finish the map and receive the second payment, I'll send you a printable final copy.</li></ol>
      <p>Despite the tone of this list, I'm not THAT much of a hardass. I am only trying to help you maximize the playability of your map. I'm not going to complain about things in your setting being unrealistic or un-Earthlike. Go wild.</p>
      <h3>Ref sheets</h3>
      <p>These sheets can be as long as you want, but they must be submitted in this format.</p>
      <p>If your campaign hasn't started yet, fill Sheet 1 with the most important places you expect your players to go.</p>
      <h4>Sheet 1: canonical information already encountered during the campaign</h4>
      <ul><li>You may include illustrations on Sheet 1.</li>
      <li><strong>They must be your own original work.</strong></li>
      <li>Schematics and diagrams are fine!</li>
          <li>I won't even look at Sheet 2 until the first draft is done.</li>
      </ul>
      <h4>Sheet 2: all other information</h4>
      <ul><li><strong>Do not submit illustrations for sheet 2.</strong></li>
      <li>Write all information in <strong>descending order of importance.</strong></li>
      <li>Specify the level of surveying technology available in your setting.</li>
      <li>Finish with a list of minor place names, or instructions for inventing minor place names.</li>
      <li>I will use place name density to convey population density, so I reserve the right to not use the entire list.</li></ul>
    </Layout>
  );
}
